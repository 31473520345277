
































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IevTipLlamCatCliente } from '@/model/main/IEvTipLlamCatCliente';
import { AxiosResponse } from 'axios';
import { IEvTipLlamCliente } from '@/model/main/IEvTipLlamCliente';
import { IEvCat } from '@/model/main/IEvCat';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';

@Component({
	name: 'TableClassificactionCategory',
	components: {
		DataTable,
	},
})
export default class TableClassificactionCategory extends MixinMain {
	public title = this.$t("MainMenu.titleTableClassificationCategory");
	public subTitle = '';
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_evTipLlam = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipLlam?${this.getFilterUrl}`
			);
		const request_evTipLlamCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evTipLlamCategCliente?${this.getFilterUrl}`
			);
		const request_evCampaCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evClienteCateg?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evTipLlam,
			request_evTipLlamCateg,
			request_evCampaCateg,
		])
			.then((responses) => {
				const response_1 = responses[0] as AxiosResponse;
				const tipLlam: Array<IEvTipLlamCliente> = response_1.data as Array<
					IEvTipLlamCliente
				>;
				const labels = Util.reduceByProperty<
					IEvTipLlamCliente,
					'tipo_llamada'
				>(tipLlam, 'tipo_llamada');

				const response_2 = responses[1] as AxiosResponse;
				const evTipLlamCats: Array<IevTipLlamCatCliente> = response_2.data as Array<
					IevTipLlamCatCliente
				>;
				const reduce = Util.reduceByProperty<
					IevTipLlamCatCliente,
					'categoria'
				>(evTipLlamCats, 'categoria');

				const response_3 = responses[2] as AxiosResponse;
				const evClienteCags = response_3.data as Array<
					IEvCat
				>;

				const titles: Array<string> = evClienteCags.map(
					(e: IEvCat): string => {
						return e.categoria;
					}
				);
				const dataTable: Array<IDataTable> = [];
				for (let label of Object.keys(labels)) {
					const row: Record<string, any> = {};
					row['id'] = Util.replaceTextWith(
						label,
						'_',
						' '
					);
					for (let title of titles) {
						const innerData: Array<IevTipLlamCatCliente> =
							reduce[title];
						const found: IevTipLlamCatCliente = innerData.find(
							(e) =>
								e.tipo_llamada === label
						) as IevTipLlamCatCliente;

						row[title] = `${parseFloat(
							found.Resultado.toString()
						).toFixed(1)}%`;
					}
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						if (key === 'id') {
							text = this.$t("MainMenu.firstTitle") as string;

						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.header = header;
				this.rows = dataTable;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenClickRow(payload: IDataTable) {
		this.$emit('changeEvent', payload.id);
	}
}
