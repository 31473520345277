























import { Component } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import BarChart from '@/components/util/BarChart.vue';

import { ChartData } from 'chart.js';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import { IEvTipLlamCliente } from '@/model/main/IEvTipLlamCliente';
import LineDialog from '@/components/portfolio/LineDialog.vue';
import ProgressChart from '@/components/main/ProgressChart.vue';
@Component({
	name: 'RowChartBar',
	components: {
		BarChart,
		LineDialog,
		ProgressChart,
	},
})
export default class RowChartBar extends MixinMain {
public titleChart = this.$t("MainMenu.titleBar");
public subTitleChart = this.$t("MainMenu.titleBarChar");
	public chartEvAudiosTip: ChartData = {
		datasets: [],
	};
	public extraData: Array<number> = [];
	public isLoading = false;
	public selectedLabel = '';
	public chartLineTitle = '';
	public chartLineOpen = false;
	mounted() {
		this.getAllData();
	}

	public getAllData() {
		this.isLoading = true;
		const request_evAudio = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipLlam?${this.getFilterUrl}`
			);
		const request_evAudioTips = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCamp?${this.getFilterUrl}`
			);
		const request_evAudioLlamCamp = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCamp?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evAudio,
			request_evAudioTips,
			request_evAudioLlamCamp,
		])
			.then((responses) => {
				this.evaluateEvAudiosTipLlam(
					responses[0] as AxiosResponse
				);
				const reduce = Util.reduceByProperty<
					IEvTipLlamCliente,
					'tipo_llamada'
				>(responses[2].data, 'tipo_llamada');
				this.evaluateExtraData(
					// responses[1] as AxiosResponse,
					responses[0] as AxiosResponse,
					reduce
				);
				this.subTitleChart = Util.replaceTextWith(
					this.subTitleChart as string,
					'%s',
					responses[2].data.length
				);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	private evaluateExtraData(
		response: AxiosResponse,
		reduce: Record<string, Array<IEvTipLlamCliente>>
	) {
		const arrayOfIEvTip: Array<IEvTipLlamCliente> =
			response.data;
		for (let evTipLlam of arrayOfIEvTip) {
			this.extraData.push(
				reduce[evTipLlam.tipo_llamada].length
			);
		}
	}
	private evaluateEvAudiosTipLlam(response: AxiosResponse) {
		const dataEvAudiosTipLlam: Array<IEvTipLlamCliente> =
			response.data;
		this.$set(
			this.chartEvAudiosTip,
			'labels',
			dataEvAudiosTipLlam.map(
				(ele: IEvTipLlamCliente): string => {
					return Util.replaceTextWith(
						ele.tipo_llamada,
						'_',
						' '
					);
				}
			)
		);
		this.chartEvAudiosTip.datasets = [
			{
				data: dataEvAudiosTipLlam.map(
					(ele: IEvTipLlamCliente): number => {
						return ele.Resultado;
					}
				),
			},
		];
	}

	public listenClickChart(payload: string) {
		this.chartLineOpen = true;
		this.chartLineTitle = Util.replaceTextWith(
			this.$t("MainMenu.lineChartTitle") as string,
			'%s',
			payload
		);
		const labelOrigen = Util.replaceTextWith(
			payload,
			' ',
			'_'
		);
		this.selectedLabel = labelOrigen;
	}

	public listenOutClick(payload: boolean) {
		this.chartLineOpen = false;
	}
}
