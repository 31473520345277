
































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IEvAgent } from '@/model/portfolio/IEvAgent';
import { IEvCateAgent } from '@/model/portfolio/IEvCateAgent';
import { IPrueba } from '@/model/portfolio/IPrueba';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IEvCampaCateg } from '@/model/main/IEvCampaCateg';
import { IEvCat } from '@/model/main/IEvCat';
import { util } from 'vue/types/umd';

@Component({
	name: 'TableClassificactionCategory',
	components: {
		DataTable,
	},
})
export default class TableClassificactionCategory extends MixinMain {
	public title = this.$t("MainPortfolio.titleAgentCat");
	public subTitle = '';
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(
				`vue-xentric/evClienteCateg?${this.getFilterUrl}`
			);
		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/evAgentCategCamp?${this.getFilterUrl}`
			);
		const request_3 = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosCategAgente?${this.getFilterUrl}`
			);
		const request_4 = internet
			.newRequest()
			.get(
				`vue-xentric/evCarteraTotalAgente?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2, request_3, request_4])
			.then((responses) => {
				// console.log(responses);
				const campCategs: Array<IEvCat> =
					responses[0].data as Array<IEvCat>;
				const agents: Array<IEvAgent> =
					responses[1].data;
				const cateAgents: Array<IEvCateAgent> =
					responses[2].data;
				const prueba: Array<IPrueba> =
					responses[3].data;
				const labels: Array<string> = campCategs.map(
					(e: IEvCat) => {
						return e.categoria;
					}
				);
				const pruebaByAgent: Record<
					string,
					Array<IPrueba>
				> = Util.reduceByProperty<
					IPrueba,
					'agente'
				>(prueba, 'agente');
				const grupByAgent: Record<
					string,
					Array<IEvAgent>
				> = Util.reduceByProperty<
					IEvAgent,
					'agente'
				>(agents, 'agente');

				const countByAgent: Record<
					string,
					Array<IEvCateAgent>
				> = Util.reduceByProperty<
					IEvCateAgent,
					'agente'
				>(cateAgents, 'agente');

				const dataTable: Array<IDataTable> = [];
				for (let agent of Object.keys(grupByAgent)) {
					const row: IDataTable = {};
					let agentText = `${agent} (${countByAgent[
						agent
					].length / labels.length})`;
					row['id'] = agentText;
					let total = 0;
					for (let label of labels) {
						const punt: number = grupByAgent[
							agent
						].find(
							(a: IEvAgent) =>
								a.categoria === label
						)?.Resultado as number;
						total += punt;
						row[label] = `${punt.toFixed(1)}%`;
					}
					// row['TAS'] = `${(
					// 	total / labels.length
					// ).toFixed(1)}%`;
					row[this.$t("MainPortfolio.lastColumnTable") as string] = `${pruebaByAgent[agent][0]['Resultado'].toFixed(1)}%`
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						if (key === 'id') {
							text = this.$t("MainPortfolio.firstColumnTable") as string;
						}
						return {
							text,
							value: key,
						};
					}
				);
				this.header = header;
				this.rows = dataTable;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenClickRow(payload: IDataTable) {
		const agent = payload.id.split('(')[0].trim();
		this.$router.push({
			name: 'Agent',
			params: {
				agent,
			},
		});
	}
}
