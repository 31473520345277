
































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IAudiosCate } from '@/model/portfolio/IAudiosCate';
import { IPrueba } from '@/model/portfolio/IPrueba';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IAgentTipoLLam } from '@/model/portfolio/IAgentTipoLlam';

@Component({
	name: 'TableAgentClassification',
	components: {
		DataTable,
	},
})
export default class TableAgentClassification extends MixinMain {
	public title = this.$t("MainPortfolio.titleAgentClass");
	public subTitle = '';
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipLlam?${this.getFilterUrl}`
			);
		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/evAgentTipoLLamCamp?${this.getFilterUrl}`
			);
		const request_3 = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamAgente?${this.getFilterUrl}`
			);
		const request_4 = internet
			.newRequest()
			.get(
				`vue-xentric/evCarteraTotalAgente?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2, request_3, request_4])
			.then((responses) => {
				const agentTipoLlams: Array<IAgentTipoLLam> =
					responses[0].data;
				const agents: Array<IAgentTipoLLam> =
					responses[1].data;
				const audiosCate: Array<IAudiosCate> =
					responses[2].data;
				const prueba: Array<IPrueba> =
					responses[3].data;
				const labels: Array<string> = agentTipoLlams.map(
					(e: IAgentTipoLLam) => {
						return e.tipo_llamada;
					}
				);
				const reduce: Record<
					string,
					Array<IAgentTipoLLam>
				> = Util.reduceByProperty<
					IAgentTipoLLam,
					'agente'
				>(agents, 'agente');
				const countAgent: Record<
					string,
					Array<IAudiosCate>
				> = Util.reduceByProperty<
					IAudiosCate,
					'agente'
				>(audiosCate, 'agente');
				const pruebaByAgent: Record<
					string,
					Array<IPrueba>
				> = Util.reduceByProperty<
					IPrueba,
					'agente'
				>(prueba, 'agente');

				const dataTable: Array<IDataTable> = [];
				for (let agent of Object.keys(reduce)) {
					const row: IDataTable = {};
					// console.log(countAgent[agent]);
					let text = `${agent} (${countAgent[
						agent
					].length })`;
					row['id'] = text;
					// let acum = 0;
					// let exist = 0;
					for (let label of labels) {
						const find:
							| IAgentTipoLLam
							| undefined = reduce[
							agent
						].find(
							(e: IAgentTipoLLam) =>
								e.tipo_llamada === label
						);
						let value = '';
						//console.log(agent, find);
						if (find === undefined) {
							value = this.$t("MainPortfolio.noData") as string;
							// acum += 0;
						} else {
							// exist += 1;
							// acum += (find! as IAgentTipoLLam)
							// 	.Resultado;
							value = `${(find! as IAgentTipoLLam).Resultado.toFixed(
								1
							)}%`;
						}

						row[label] = value;
					}
					// row['TAS'] = `${(acum / exist).toFixed(
					// 	1
					// )}%`;
					row[this.$t("MainPortfolio.lastColumnTable") as string] = `${pruebaByAgent[agent][0]['Resultado'].toFixed(1)}%`;
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						if (text === 'id') {
							text = this.$t("MainPortfolio.firstColumnTable") as string;
						}
						return {
							text,
							value: key,
						};
					}
				);
				this.header = header;
				this.rows = dataTable;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenClickRow(payload: IDataTable) {
		const agent = payload.id.split('(')[0].trim();
		this.$router.push({
			name: 'Agent',
			params: {
				agent,
			},
		});
	}
}
